import { Dispatch } from 'react';
import { BUYSELL, CPM, SELECT, TANSO, TEN } from '../../api/constants';
import {
  Action,
  IReconReportItemCpm,
  IReconReportItemTanso,
  IReconReportTotalVolume,
  IReconReportTotalVolumeTanso,
} from '../../Types/types';

export const decorateColName = (columnName: string) => {
  const valueWithoutSpecialCharacters = columnName.replace(/[^a-zA-Z]/g, ' ');
  const valueAllLowerCase = valueWithoutSpecialCharacters.toLowerCase();
  const valueFirstLetterCaps = valueAllLowerCase[0].toUpperCase() + valueAllLowerCase.substring(1);

  if (valueFirstLetterCaps.replace(' ', '').toUpperCase() === BUYSELL) {
    return 'Buy/Sell';
  } else if (valueFirstLetterCaps.replace(' ', '').toUpperCase() === 'NCFSNAPSHOTID') {
    return 'NCF snapshot id';
  } else {
    return valueFirstLetterCaps;
  }
};

// converting all TANSO text to CPM in an array of objects
export const convertKeys = (obj: IReconReportItemTanso) => {
  const newObj: IReconReportItemCpm = {
    YEAR: 0,
    MONTH: '',
    NCF_CATEGORY: '',
    CPTY: '',
    BUY_SELL: '',
    CPM_VOLUME: 0,
    ETRM_VOLUME: 0,
    DELTA_VOLUME: 0,
    CPM_COUNT: 0,
    ETRM_COUNT: 0,
  };
  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      const newKey = key.replace(/TANSO/g, 'CPM');
      newObj[newKey] = obj[key as keyof IReconReportItemTanso];
    }
  }
  return newObj;
};

// converting all TANSO text to CPM in an object of objects
export const replaceKeyTansoToCpm = (obj: IReconReportTotalVolumeTanso) => {
  const newObj: IReconReportTotalVolume = {
    CPM: {
      TOTAL_VOLUME: 0,
      TOTAL_COUNT: 0,
    },
    ETRM: {
      TOTAL_VOLUME: 0,
      TOTAL_COUNT: 0,
    },
    DELTA: {
      TOTAL_VOLUME: 0,
      TOTAL_COUNT: 0,
    },
  };
  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      const newKeyName = key.includes(TANSO) ? key.replace(TANSO, CPM) : key;
      newObj[newKeyName as keyof IReconReportTotalVolume] =
        obj[key as keyof IReconReportTotalVolumeTanso];
    }
  }
  return newObj;
};

export const areStringsEqual = (val: string, col: string) => {
  if (col === undefined) {
    return false;
  }
  return (
    // /[^a-zA-Z]/g is to pick up everything except a-z and A-Z
    val.toLowerCase().replace(/[^a-zA-Z]/g, '') === col.toLowerCase().replace(/[^a-zA-Z]/g, '')
  );
};

export const formatDate = (date: Date): string => {
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear();

  // Pad the day and month with leading zeros if necessary
  const formattedDay = day < TEN ? `0${day}` : day.toString();
  const formattedMonth = month < TEN ? `0${month}` : month.toString();

  // Return the formatted date string in dd/mm/yyyy format
  return `${formattedDay}/${formattedMonth}/${year}`;
};

export const showSpinner = (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'setSpinner', payload: true });
};

export const hideSpinner = (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'setSpinner', payload: false });
};

export const getDefaultOptions = () => [{ value: SELECT, label: SELECT }];

export const getCombinedMonths = (quarters: string[]): string[] => {
  const monthsByQuarter = {
    Q1: ['January', 'February', 'March'],
    Q2: ['April', 'May', 'June'],
    Q3: ['July', 'August', 'September'],
    Q4: ['October', 'November', 'December'],
  };

  return quarters.flatMap((quarter) => monthsByQuarter[quarter as keyof typeof monthsByQuarter]);
};

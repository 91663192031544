/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, ForwardedRef, useEffect, useState } from 'react';
import Highcharts, { SeriesLineOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Boost from 'highcharts/modules/boost';
import exportingModule from 'highcharts/modules/exporting';
import ErrorMessage from './ErrorMessage';
import { ChartWrapper } from './Chart.styles';
import useHasData from './useHasData';
import { GlassChartTheme } from '@sede-x/glass-design-library';
import { eight, one, seven, ten, three, two, zero } from '../../constants';
import { formatDateTimeDowloadFile, makeNumberFromDigits } from '../../utils';
import HC_offlineExporting from 'highcharts/modules/offline-exporting';
import { getTransformSource } from '../CommodityOverview/Utils';

Boost(Highcharts);
exportingModule(Highcharts);
HC_offlineExporting(Highcharts);

export type ChartProps = HighchartsReact.Props & {
  options: Highcharts.Options;
  className?: string;
  'data-testid'?: string;
  key?: string | number;
  chartKey?: string | number;
  source: string;
  disclaimer?: string;
};

const Chart = forwardRef(
  (
    {
      options: initialOptions,
      source,
      disclaimer,
      className = '',
      'data-testid': testId,
      key,
      chartKey,
      ...props
    }: ChartProps,
    ref: ForwardedRef<HighchartsReact.RefObject>,
  ) => {
    const [chartRef, setChartRef] = useState<Highcharts.Chart>();
    const [sourceText, setSourceText] = useState<Highcharts.SVGElement | null>(null);
    const updatedSource = source && getTransformSource(source.split(',').filter(item => !item.includes('Carbon KPI Calculator')).join(','));
    const hasData = useHasData(initialOptions.series as SeriesLineOptions[]);
    const exportingOptions = {
      enabled: true,
      sourceWidth: 1500,
      sourceHeight: 500,
      filename: `${chartKey}_${formatDateTimeDowloadFile(new Date())}`,
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          symbol: 'menuball',
          symbolStrokeWidth: 1,
          symbolSize: 15,
          symbolFill: '#343434',
          x: -makeNumberFromDigits([one, zero]),
          y: makeNumberFromDigits([two]),
          menuItems: ['downloadJPEG', 'downloadPDF'],
        },
      },
    };
    const customLoad = (chart: Highcharts.Chart) => {
      setChartRef(chart);
      const wordWrap = 'break-word';
      const sourceHeight = disclaimer
        ? chart.chartHeight - makeNumberFromDigits([three, eight])
        : chart.chartHeight - makeNumberFromDigits([one, eight]);
      // Clear existing text to prevent overlap
      if (sourceText) {
        sourceText.destroy();
      }
      const newSourceText = chart?.renderer
        ?.text(
          `Source: ${updatedSource?.toString()}`,
          makeNumberFromDigits([one, zero]),
          sourceHeight,
        )
        .css({
          color: '#777',
          fontSize: '12px',
          fontStyle: 'italic',
          width: 1500,
          wordWrap,
        })
        .add();
      setSourceText(newSourceText);
      if (disclaimer && Array.isArray(disclaimer)) {
        const startY = chart.chartHeight - makeNumberFromDigits([two, three]);
        // Render the "Disclaimer" title
        chart?.renderer
          ?.text('Disclaimer:', makeNumberFromDigits([one, zero]), startY + two)
          .css({
            color: '#777',
            fontSize: '12px',
            fontStyle: 'italic',
            width: 1200,
            wordWrap,
          })
          .add();
        const length = disclaimer?.length;
        // Render each disclaimer item as a bullet point
        disclaimer.forEach((item, index) => {
          const itemToString = item?.toString();
          chart?.renderer
            ?.text(
              length > 1 ? `•${itemToString}` : itemToString,
              makeNumberFromDigits([seven, zero]),
              startY + (index + one) * makeNumberFromDigits([one, three]) - ten, // Adjust position for each bullet point
            )
            .css({
              color: '#777',
              fontSize: '12px',
              fontStyle: 'italic',
              width: 1200,
              wordWrap,
            })
            .add();
        });
      }
    };
    const options: Highcharts.Options = {
      ...initialOptions,
      chart: {
        ...initialOptions.chart,
        events: {
          load() {
            customLoad(this);
          },
        },
      },
      exporting: exportingOptions,
    };
    useEffect(() => {
      chartRef && customLoad(chartRef);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);
    options?.series?.map((serie) => {
      serie.name = serie.name?.replace(
        'External Gas Sales Volumes (Pipeline)',
        'Third Party Pipeline Gas',
      );
      serie.name = serie.name?.replace(
        'External Gas Sales Volumes (Renewable)',
        'Third Party Renewble Gas',
      );
    });
    const keyObject = key ? { key } : {};
    return (
      <ChartWrapper>
        {hasData ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={GlassChartTheme({ options })}
            containerProps={{
              className: `chart-container ${className}`,
            }}
            ref={ref}
            {...props}
            {...keyObject}
            data-testid={testId}
          />
        ) : (
          <ErrorMessage message='No data found for chart' />
        )}
      </ChartWrapper>
    );
  },
);

Chart.displayName = 'Chart';
export default Chart;

/* eslint-disable no-unused-vars */
import {
  Country,
  IBiaInactiveCptyData,
  IBiaReports,
  IBiaReportsAction,
  IBiaUncatCptyAsiaAustraliaData,
  IBiaUncatCptyEuropeAmericaData,
  IBiaValidationCheckData,
  IOptions,
} from '../../../../Types/types';
import {
  GPTV,
  ONE_MINUS_TWO,
  TRANSACTION_TYPE,
  VOLUME_BY_CATEGORY,
} from '../../../../api/constants';
import {
  filterData,
  getBiaGroupAffData,
  getBiaInactiveCptyData,
  getBiaUncatCptyData,
  getBiaValidationCheckData,
  lastRefesh,
} from '../../../../api/services';
import { Dispatch, SetStateAction } from 'react';

type stringNumberType = string | string[] | number;
// Function to check if the value is empty (empty string or empty array)
const isEmpty = (value: stringNumberType | undefined) =>
  value === '' || (Array.isArray(value) && value.length === 0);
// New function to filter out empty attributes from options
const filterEmptyAttributes = (options: IOptions) => {
  return Object.entries(options).reduce((acc, [key, value]) => {
    if (!isEmpty(value)) {
      acc[key as keyof IOptions] = value;
    }
    return acc;
  }, {} as IOptions);
};

type IValidationCheckData = {
  Region: string;
  Commodity: string;
  [TRANSACTION_TYPE]: string;
  [GPTV]: number;
  'Source UOM': string;
  [ONE_MINUS_TWO]: number;
  [VOLUME_BY_CATEGORY]: number;
};

type AggregatedData = {
  [key: string]: IValidationCheckData;
};

export const fetchBiaValidationCheckData = async (
  state: IBiaReports,
  dispatch: Dispatch<IBiaReportsAction>,
  userRegion: string,
) => {
  const options = {
    yearFilter: state.biaFilterData.biaYear,
    quarter: state.biaFilterData.biaQuarter,
    month: state.biaFilterData.biaMonth,
    region: userRegion || state.biaFilterData.biaRegion,
    country: state.biaFilterData.biaCountry,
    commodity: state.biaFilterData.biaCommodity,
    unit: state.biaFilterData.biaUnitName,
  };
  dispatch({
    type: 'setIsValidationDataLoading',
    payload: true,
  });
  const filteredOptions = filterEmptyAttributes(options);
  try {
    const response = await getBiaValidationCheckData(filteredOptions);
    if (response.data.length > 0) {
      // Update the Region property for each item in the response data
      const updatedData = response.data.map((item: IBiaValidationCheckData) => ({
        ...item,
        Region: (filteredOptions.region as string).toUpperCase(),
      }));
      // Aggregate data by Commodity and Transaction Type
      const aggregatedData = updatedData.reduce(
        (acc: AggregatedData, item: IValidationCheckData) => {
          const {
            Commodity,
            [TRANSACTION_TYPE]: transactionType,
            [GPTV]: gptv,
            [ONE_MINUS_TWO]: diff,
            [VOLUME_BY_CATEGORY]: volume,
          } = item;

          const key = `${Commodity}-${transactionType}`;

          if (!acc[key]) {
            acc[key] = {
              ...item,
              [GPTV]: 0,
              [ONE_MINUS_TWO]: 0,
              [VOLUME_BY_CATEGORY]: 0,
            };
          }

          acc[key][GPTV] += gptv;
          acc[key][ONE_MINUS_TWO] += diff;
          acc[key][VOLUME_BY_CATEGORY] += volume;

          return acc;
        },
        {} as AggregatedData,
      );
      // Convert the aggregated data to an array
      const result = Object.values(aggregatedData);
      dispatch({
        type: 'setBiaValidationCheckData',
        payload: result as IBiaValidationCheckData[],
      });
    } else {
      dispatch({
        type: 'setBiaValidationCheckData',
        payload: [],
      });
    }
  } catch (error) {
    const e = error as { message: string };
    dispatch({
      type: 'setBiaValidationCheckData',
      payload: [],
    });
    setUpServerErrorView(dispatch, e);
  }
  dispatch({
    type: 'setIsValidationDataLoading',
    payload: false,
  });
};

export const setUpServerErrorView = (
  dispatch: Dispatch<IBiaReportsAction>,
  error: { message: string },
) => {
  dispatch({
    type: 'setIsNcfServerError',
    payload: true,
  });
  dispatch({
    type: 'setNcfServerErrorMessage',
    payload: error.message,
  });
};

export const fetchBiaGroupAffData = async (
  state: IBiaReports,
  dispatch: Dispatch<IBiaReportsAction>,
) => {
  const options = {
    yearFilter: state.biaFilterData.biaYear,
    quarter: state.biaFilterData.biaQuarter,
    month: state.biaFilterData.biaMonth,
    region: state.biaFilterData.biaRegion,
    country: state.biaFilterData.biaCountry,
    commodity: state.biaFilterData.biaCommodity,
    unit: state.biaFilterData.biaUnitName,
  };
  const filteredOptions = filterEmptyAttributes(options);
  try {
    dispatch({
      type: 'setIsGroupAffDataLoading',
      payload: true,
    });
    const response = await getBiaGroupAffData(filteredOptions);
    if (response.data.length > 0) {
      dispatch({
        type: 'setBiaGroupAffData',
        payload: response.data,
      });
    } else {
      dispatch({
        type: 'setBiaGroupAffData',
        payload: [],
      });
    }
  } catch (error) {
    const e = error as { message: string };
    dispatch({
      type: 'setBiaGroupAffData',
      payload: [],
    });
    setUpServerErrorView(dispatch, e);
  }
  dispatch({
    type: 'setIsGroupAffDataLoading',
    payload: false,
  });
};

export const fetchBiaInactiveCptyData = async (
  state: IBiaReports,
  dispatch: Dispatch<IBiaReportsAction>,
) => {
  const options = {
    yearFilter: state.biaFilterData.biaYear,
    quarter: state.biaFilterData.biaQuarter,
    month: state.biaFilterData.biaMonth,
    region: state.biaFilterData.biaRegion,
    country: state.biaFilterData.biaCountry,
    commodity: state.biaFilterData.biaCommodity,
    unit: state.biaFilterData.biaUnitName,
  };
  const filteredOptions = filterEmptyAttributes(options);
  try {
    dispatch({
      type: 'setIsInactiveCptyDataLoading',
      payload: true,
    });
    const response = await getBiaInactiveCptyData(filteredOptions);
    if (response.data.length > 0) {
      // Update the Region property for each item in the response data
      const updatedData = response.data.map((item: IBiaInactiveCptyData) => ({
        ...item,
        Region: (filteredOptions.region as string).toUpperCase(),
      }));
      dispatch({
        type: 'setBiaInactiveCptyData',
        payload: updatedData,
      });
    } else {
      dispatch({
        type: 'setBiaInactiveCptyData',
        payload: [],
      });
    }
  } catch (error) {
    const e = error as { message: string };
    dispatch({
      type: 'setBiaInactiveCptyData',
      payload: [],
    });
    setUpServerErrorView(dispatch, e);
  }
  dispatch({
    type: 'setIsInactiveCptyDataLoading',
    payload: false,
  });
};

export const fetchUncatCptyData = async (
  isBiaRegionEuropeAmericas: boolean,
  state: IBiaReports,
  dispatch: Dispatch<IBiaReportsAction>,
) => {
  const options = {
    yearFilter: state.biaFilterData.biaYear,
    quarter: state.biaFilterData.biaQuarter,
    month: state.biaFilterData.biaMonth,
    region: state.biaFilterData.biaRegion,
    country: state.biaFilterData.biaCountryCode,
    commodity: state.biaFilterData.biaCommodity,
    unit: state.biaFilterData.biaUnitName,
  };
  const filteredOptions = filterEmptyAttributes(options);
  const dispatchFunction = isBiaRegionEuropeAmericas
    ? dispatchEuropeAmericaData
    : dispatchAsiaAustraliaData;
  try {
    dispatch({
      type: 'setIsUncatCptyDataLoading',
      payload: true,
    });
    const response = await getBiaUncatCptyData(filteredOptions);
    if (response.data.length > 0) {
      dispatchFunction(response.data, dispatch);
    } else {
      dispatchFunction([], dispatch);
    }
  } catch (error) {
    const e = error as { message: string };
    dispatchFunction([], dispatch);
    setUpServerErrorView(dispatch, e);
  }
  dispatch({
    type: 'setIsUncatCptyDataLoading',
    payload: false,
  });
};

export const dispatchEuropeAmericaData = (
  data: IBiaUncatCptyEuropeAmericaData[],
  dispatch: Dispatch<IBiaReportsAction>,
) => {
  dispatch({
    type: 'setBiaUncatCptyEuropeAmericaData',
    payload: data,
  });
};

export const dispatchAsiaAustraliaData = (
  data: IBiaUncatCptyAsiaAustraliaData[],
  dispatch: Dispatch<IBiaReportsAction>,
) => {
  dispatch({
    type: 'setBiaUncatCptyAsiaAustraliaData',
    payload: data,
  });
};

export const fetchFilterInfo = async (
  dispatch: Dispatch<IBiaReportsAction>,
  setYearSelected: Dispatch<SetStateAction<string[]>>,
  handleRegionChange: (e: string, regionCountryMapping?: Record<string, Country[]>) => void,
  userRegion?: string,
) => {
  dispatch({
    type: 'setIsBiaFilterOptionsLoading',
    payload: true,
  });
  try {
    const response = await filterData();
    dispatch({
      type: 'setBiaFilterOptions',
      payload: response.data,
    });
    // Set default values after data has been fetched
    setYearSelected([response.data.year[0].toString()]);
    const region = userRegion ?? response.data.region[0];
    handleRegionChange(region, response.data.regionCountryMapping);
  } catch (error) {
    const e = error as { message: string };
    setUpServerErrorView(dispatch, e);
  }
  dispatch({
    type: 'setIsBiaFilterOptionsLoading',
    payload: false,
  });
};

export const fetchSideCardData = async (
  dispatch: Dispatch<IBiaReportsAction>,
  setDates: Dispatch<
    SetStateAction<{
      ASIA: string;
      AMERICAS: string;
      EUROPE: string;
      AUSTRALIA: string;
    }>
  >,
) => {
  try {
    const result = await lastRefesh();
    setDates(result.data);
  } catch (error) {
    const e = error as { message: string };
    setUpServerErrorView(dispatch, e);
  }
};
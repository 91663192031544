import { useEffect, useContext } from 'react';
import { GeoChart } from '@sede-x/glass-design-library';
import { AppContext } from '../../../../../../Context/AppContext';
import { MapContainer, Spacer, GeoChartContainer } from './PowerNcfVolumeMap.style';
import mapdatanew from '@highcharts/map-collection/custom/world.topo.json';
import { ncfByCountry } from '../../../../../../api/services';

const PowerNCFVolumeMap = () => {
  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).analyticsHome;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ncfByCountry(analyticsState.filteredValues);
        analyticsDispatch({
          type: 'setNcfByCountry',
          payload: response.data,
        });
      } catch (error) {
        // Log error once toast feature is implemented
      }
    };
    fetchData();
  }, [analyticsDispatch, analyticsState.filteredValues]);

  const getOnClicked = () => {
    // Handle on bubble click here
  };

  const mapData = analyticsState.ncfByCountry.map(
    (item: { COUNTRY_CODE: string; QTY: number; UNIT: string }) => ({
      code3: item?.COUNTRY_CODE,
      z: item?.QTY,
      UNIT: item?.UNIT,
    }),
  );
  return (
    <MapContainer data-testid='map-container'>
      <Spacer>
        <GeoChartContainer data-testid='geo-chart'>
          <GeoChart
            key={JSON.stringify(mapData)}
            options={{
              chart: {
                borderWidth: 0,
                map: mapdatanew,
              },
              mapNavigation: {
                enabled: true,
                buttonOptions: {
                  verticalAlign: 'bottom',
                },
              },

              title: {
                text: 'NCF Volume Map',
                align: 'left',
              },
              legend: {
                enabled: false,
              },
              credits: {
                enabled: false,
              },

              plotOptions: {
                series: {
                  point: {
                    events: {
                      click: () => getOnClicked(),
                    },
                  },
                },
              },

              series: [
                {
                  name: 'Countries',
                  color: '#E0E0E0',
                  borderColor: '#A0A0A0',
                  nullColor: 'rgba(200, 200, 200, 0.3)',
                  showInLegend: false,
                  enableMouseTracking: false,
                },
                {
                  type: 'mapbubble',
                  name: 'Countries',
                  joinBy: ['iso-a3', 'code3'],
                  data: mapData,
                  minSize: 10,
                  maxSize: '8%',
                  tooltip: {
                    pointFormat:
                      '<span style="font-weight: 600;">Country Name:</span> {point.name}<br><span style="font-weight: 600;">NCF Volume:</span> {point.z}<br><span style="font-weight: 600;">UNIT:</span> {point.UNIT}',
                    headerFormat: '',
                  },
                },
              ],
            }}
          />
        </GeoChartContainer>
      </Spacer>
    </MapContainer>
  );
};

export default PowerNCFVolumeMap;
